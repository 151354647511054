/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
:root {
  --primary: #3ba4bf;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/*
CUSTOM STYLES FOR EVOLUTICS
@Sinaly
*/
nav,
.nav-pills {
  width: 100%;
}

a {
  text-decoration: none;
}
.mdc-tooltip,
.mdc-tooltip * {
  max-width: unset !important;
}

.table .square {
  margin: 0
}
.loading-content-container{
  height: 100%;
}